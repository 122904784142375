import React, { Component } from 'react';
import styled from 'styled-components';

import HelmetMetaData from './../modules/MetaData/components/HelmetMetaData';
import bgimg from '../imgs/poza_Pag_servicii_opaca.jpg';

const MyH1 = styled.h1`
    text-align:center;
    margin: 0 0 75px 0;
`;
const MySection = styled.section`
    max-width:90%;
    margin:0 auto;
    background: url(${bgimg});
    border-top: 1px dotted rgb(233, 235, 31);
    border-left: 1px dotted rgb(233, 235, 31);
    padding: 100px;
    margin-bottom: 150px;
    font-size: 1.25rem;
    border: 1px dotted rgb(233, 235, 31);
        @media (max-width: 640px){
             border: 0px dotted rgb(233, 235, 31);
            max-width:100%;
            font-size:1rem;
            padding: 25px;
        }
`;
export class Servicii extends Component {

    render() {
        return (
            <div>
                <HelmetMetaData
                    title="Investigatii Vip | companie de securitate in Timisora | Servicii"
                    description="Investigatii Vip | companie de securitate in Timisora | Servicii"
                    keywords="Investigatii Vip, companie de securitate, Timisora, interventie rapida,investigatie, protectia bunurilor si persoanelor, Servicii" />

                <MyH1>Servicii</MyH1>
                <MySection>
                    <p>Obiectivele firmei sunt de a se angaja în mod eficient şi responsabil, cu personal calificat, în activităţi din domeniul pazei şi protecţiei persoanelor reşedinţelor, sediilor de firme, spaţiilor de producţie, complexelor comerciale, clădirilor şi instituţiilor publice.</p>

                    <ul>
                        <li>Asigurarea pazei şi securităţii obiectivului dumneavoastră cu agenti de paza, în baza unui plan de pază realizat în funcţie de specificul activităţii  şi solicitările conducerii societăţii beneficiare. Societatea are în dotare materiale şi aparatură specifică obiectivului.</li>

                        <li>Activitati de interventie rapida la obiectivul dumneavoastra, investigatie, protectia bunurilor si persoanelor, conform legii, cu personal selectionat si atestat in domeniu.</li>

                        <li>Servicii de: investigatii, filaje, urmariri supravegheri, verificarea datelor personale ale angajatilor firmei dumneavoastra, verificarea antecedentelor unor persoane, supravegherea copiilor, de asemenea cu personal selectionat si atestat in domeniu.</li>

                        <li>Servicii de paza si securitate la evenimente private, concerte, evenimente sportive.</li>

                        <li>Proiectare si instalare: sisteme de alarma la efractie, la incendiu, sisteme de control acces, sisteme de supraveghere video.</li>
                    </ul>

                    <p>Societatea noastră este asigurată pentru pagubele produse beneficiarului din vina exclusivă a personalului prestatorului, dovedită în urma unei cercetări în comun: beneficiar, prestator, poliţie.</p>

                    <p>Pentru situaţiile deosebite, societatea va sprijini acţiunea agenţilor de pază cu echipa mobilă de intervenţie a carei costuri intra in tariful general prezentat clientului.</p>

                    <p>La calcularea tarifului se iau în considerare valoarea obiectivului pazit respectiv obiectul de activitate in conformitate cu GH301/2012,  toate elementele care compun costurile asigurării activitatii de paza si protectie cum sunt: salarizarea agentilor de securitate, cheltuieli de transport, dotarea specifica indeplinirii misiunilor de protectie (agentul este echipat cu uniforma specifica, tomfa, spray iritant lacrimogen, mijloace de comunicatie telefon mobil), cheltuieli de organizarea si intocmirea documentatiei legale pentru astfel de misiuni, conform <strong>Legii nr. 333/2003 si HG301/2012.</strong></p>

                    <p>În situaţia în care doriti o colaborare cu noi, urmeaza sa  desfasuram mai multe activităţi preliminare cum ar fi: analiza situaţiei operative, stabilirea punctelor vulnerabile, stabilirea procedurilor ce trebuiesc executate de către personalul de paza şi personalul societatii beneficiare în rezolvarea unor situaţii, instruirea şi dotarea personalului de paza, asigurarea cu echipament şi tehnică de pază, întocmirea documentelor care reglementează activitatea de paza si protectie.</p>
                </MySection>
            </div>
        );
    }
}