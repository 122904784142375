import React, { Component } from 'react';
import HelmetMetaData from './../modules/MetaData/components/HelmetMetaData';
import styled from 'styled-components';

const IframeWrap = styled.div`
    width:100%;
    height:50vh;
`;
const MyH1 = styled.h1`
    text-align:center;
    margin: 0 0 75px 0;
`;
export class Contact extends Component {
    static displayName = Contact.name;
    render() {
        return (
            <div>
                
                <HelmetMetaData
                    title="Investigatii Vip | companie de securitate in Timisora | Contact"
                    description="Investigatii Vip | companie de securitate in Timisora | Contact"
                    keywords="Investigatii Vip, companie de securitate, Timisora, interventie rapida,investigatie, protectia bunurilor si persoanelor, Contact" />

                <div className="contactWrap">
                    <MyH1>Contact</MyH1>
                    <IframeWrap>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4680.865809154999!2d21.252104087347714!3d45.76599016441089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4745676da54f2115%3A0xa479946601f351ba!2sStrada%20Rena%C5%9Fterii%2029%2C%20Timi%C8%99oara!5e0!3m2!1sen!2sro!4v1606113059828!5m2!1sen!2sro" width="100%" height="100%" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" title="Google Map"></iframe>
                    </IframeWrap>
                </div>    
            </div>
        );
    }
}
