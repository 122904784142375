import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './../../Layout/components/Layout';
import { Home } from './../../../pages/Home';
import { DespreNoi } from './../../../pages/DespreNoi';
import { Servicii } from './../../../pages/Servicii';
import { Galerie } from './../../../pages/Galerie';
import { Contact } from './../../../pages/Contact';
import  CacheBuster  from './../../../CacheBuster';

import './custom.css'


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <div>
                    <Layout>                        
                        <Routes>
                            <Route exact path='/' element={<Home/>} />
                            <Route exact path="/despre-noi" element={<DespreNoi/>} />
                            <Route exact path="/servicii" element={<Servicii/>} />
                            <Route exact path="/galerie" element={<Galerie/>} />
                            <Route exact path="/contact" element={<Contact/>} />
                        </Routes>
                    </Layout>
                    </div>
                );
            }}
        </CacheBuster>

    );
  }
}
