import React, { Component } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import img1 from './../imgs/gallery/1.jpg';
import img2 from './../imgs/gallery/2.jpg';
import img3 from './../imgs/gallery/3.jpg';
import img4 from './../imgs/gallery/4.jpg';
import img5 from './../imgs/gallery/5.jpg';
import img6 from './../imgs/gallery/6.jpg';
import img7 from './../imgs/gallery/7.jpg';
import img8 from './../imgs/gallery/8.jpg';
import img9 from './../imgs/gallery/9.jpg';

import img1s from './../imgs/gallery/small/1.jpg';
import img2s from './../imgs/gallery/small/2.jpg';
import img3s from './../imgs/gallery/small/3.jpg';
import img4s from './../imgs/gallery/small/4.jpg';
import img5s from './../imgs/gallery/small/5.jpg';
import img6s from './../imgs/gallery/small/6.jpg';
import img7s from './../imgs/gallery/small/7.jpg';
import img8s from './../imgs/gallery/small/8.jpg';
import img9s from './../imgs/gallery/small/9.jpg';

//2021
import img2021_1 from './../imgs/gallery/2021/1.jpg';
import img2021_2 from './../imgs/gallery/2021/2.jpg';
import img2021_3 from './../imgs/gallery/2021/3.jpg';
import img2021_4 from './../imgs/gallery/2021/4.jpg';
import img2021_5 from './../imgs/gallery/2021/5.jpg';


import img2021_1s from './../imgs/gallery/2021/small/1.jpg';
import img2021_2s from './../imgs/gallery/2021/small/2.jpg';
import img2021_3s from './../imgs/gallery/2021/small/3.jpg';
import img2021_4s from './../imgs/gallery/2021/small/4.jpg';
import img2021_5s from './../imgs/gallery/2021/small/5.jpg';

import HelmetMetaData from './../modules/MetaData/components/HelmetMetaData';

const MyH1 = styled.h1`
    text-align:center;
    margin: 0 0 75px 0;
`;

const images = [
    {
        original: img2021_1,
        thumbnail: img2021_1s,
    },
    {
        original: img2021_2,
        thumbnail: img2021_2s,
    },
    {
        original: img2021_3,
        thumbnail: img2021_3s,
    },
    {
        original: img2021_4,
        thumbnail: img2021_4s,
    },
    {
        original: img2021_5,
        thumbnail: img2021_5s,
    },
    {
        original: img1,
        thumbnail: img1s,
    },
    {
        original: img2,
        thumbnail: img2s,
    },
    {
        original: img3,
        thumbnail: img3s,
    },
    {
        original: img4,
        thumbnail: img4s,
    },
    {
        original: img5,
        thumbnail: img5s,
    },
    {
        original: img6,
        thumbnail: img6s,
    },
    {
        original: img7,
        thumbnail: img7s,
    },
    {
        original: img8,
        thumbnail: img8s,
    },
    {
        original: img9,
        thumbnail: img9s,
    },    

];

export class Galerie extends Component {

    render() {
        return (
            <div>

                <HelmetMetaData
                    title="Investigatii Vip | companie de securitate in Timisora | Galerie"
                    description="Investigatii Vip | companie de securitate in Timisora | Galerie"
                    keywords="Investigatii Vip, companie de securitate, Timisora, interventie rapida,investigatie, protectia bunurilor si persoanelor, galerie foto, dotari" />

                <MyH1>Galerie</MyH1>
                <ImageGallery
                    items={images}
                    thumbnailPosition="left" />
            </div>
        );
    }
}