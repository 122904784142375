import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import mail from './../../../imgs/mail.png';
import location from './../../../imgs/location.png';
import phone from './../../../imgs/phone.png';


const MyFooter = styled.footer`
    margin: 0 0 0;
    background:#111111;
`;
const MyFooterWrap = styled.div`
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    display:flex;
    @media (max-width: 640px){
        padding:20px 0;
    }
`;

const BoxFooter = styled.div`
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 27%;
    text-align: center;
    margin: 3%;
    font-weight:300;
    @media (max-width: 1024px){
        margin:2%;
    }
    @media (max-width: 920px){
        margin:1%;
    }
    @media (max-width: 860px){
        margin: 0;
        flex-basis: 100%;
    }
    ${props => props.border && css`
         border:dotted 1px #e9eb1f;
          @media (max-width: 860px){
                border-width:0px;
            }
        `}
`;
const BoxInnerWrap = styled.div`
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    display:flex;
    padding:50px 0;
    @media (max-width: 860px){
        padding:10px 0;
    }
`;
const BoxInnerImg = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 24%;
    text-align:right;
    padding-right:10px;
     @media (max-width: 860px){
        flex-basis: 100%;
        text-align:center;
    }
`;
const BoxInnerText = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 76%;
     @media (max-width: 1150px){
        font-size:14px;
    }
    @media (max-width: 860px){
        flex-basis: 100%;
        text-align:center;
    }
`;

const BoxTitle = styled.div`
    text-align:left;
    @media (max-width: 860px){
        text-align:center;
    }
`;
const BoxContent = styled.div`
    text-align:left;
    font-weight:500;
    @media (max-width: 860px){
        text-align:center;
    }
`;
const Copyright = styled.div`
    font-size:18px;
    font-weight:300;
    text-align:center;
    background:#000000;
    padding-top:20px;
    @media (max-width: 860px){
        font-size:13px;
    }
`;
const CookiePolicy = styled.div`
    font-size:14px;
    @media (max-width: 860px){
            font-size:11px;
        }
`;

const Dezvoltator = styled.div`
    font-size:11px;
    font-weight:300;
    text-align:center;
    background:#000000;
`;

export class Footer extends Component {
    static displayName = Footer.name;
    render() {
        return (
            <MyFooter>
                <MyFooterWrap>
                    <BoxFooter border>
                        <BoxInnerWrap>
                            <BoxInnerImg><img src={location} alt="location" /></BoxInnerImg>
                            <BoxInnerText><BoxTitle>Adresa</BoxTitle><BoxContent>Timisoara, Str. Renasterii, Nr. 29</BoxContent></BoxInnerText>
                        </BoxInnerWrap>
                    </BoxFooter>
                    <BoxFooter border>
                        <BoxInnerWrap>
                            <BoxInnerImg><img src={mail} alt="mail" /></BoxInnerImg>
                            <BoxInnerText><BoxTitle>Email</BoxTitle><BoxContent>investigatiivip@yahoo.ro</BoxContent></BoxInnerText>
                        </BoxInnerWrap>
                    </BoxFooter>
                    <BoxFooter border>
                        <BoxInnerWrap>
                            <BoxInnerImg><img src={phone} alt="phone" /></BoxInnerImg>
                            <BoxInnerText><BoxTitle>Telefon</BoxTitle><BoxContent>0762236351</BoxContent></BoxInnerText>
                        </BoxInnerWrap>
                    </BoxFooter>
                </MyFooterWrap>
                <Copyright>Copyright {(new Date().getFullYear())} - INVESTIGATII VIP<br /><CookiePolicy>Politica cookie</CookiePolicy></Copyright>
                <Dezvoltator><br/>Dezvoltator: <a href="https://nasaragrafik.com">Nasara Grafik</a></Dezvoltator>
            </MyFooter>
        );
    };
}