import React, { Component } from 'react';
import styled from 'styled-components';
import bgimg from '../imgs/poza_pag_despre_noi_opaca.jpg';

import HelmetMetaData from './../modules/MetaData/components/HelmetMetaData';

const MyH1 = styled.h1`
    text-align:center;
    margin: 0 0 75px 0;
`;
const MySection = styled.section`
    max-width:90%;
    margin:0 auto;
    background: url(${bgimg});
    border-top: 1px dotted rgb(233, 235, 31);
    border-left: 1px dotted rgb(233, 235, 31);
    padding: 100px;
    margin-bottom: 150px;
    font-size: 1.25rem;
    /* background: red; */
    border: 1px dotted rgb(233, 235, 31);
@media (max-width: 640px){
             border: 0px dotted rgb(233, 235, 31);
            max-width:100%;
            font-size:1rem;
            padding: 25px;
        }
`;
export class DespreNoi extends Component {

    render() {
        return (
            <div>
                <HelmetMetaData
                    title="Investigatii Vip | companie de securitate in Timisora | Despre Noi"
                    description="Investigatii Vip | companie de securitate in Timisora | Despre Noi"
                    keywords="Investigatii Vip, companie de securitate, Timisora, interventie rapida,investigatie, protectia bunurilor si persoanelor, Despre noi" />

                <MyH1>Despre Noi</MyH1>
                <MySection>
                    <p><strong>S.C.  INVESTIGATII V.I.P. S.R.L.</strong>  este o societate cu capital privat integral românesc, formata dintr-un colectiv tanar, având ca obiect de activitate prestarea de servicii în domeniul securităţii private. Societatea funcţionează în baza <strong>Legii nr. 333/2003</strong>, precum şi în baza <strong>Avizului Inspectoratului General al Poliţiei Române</strong>, supunându-se controlului periodic al organelor abilitate.</p>
                    <p>Infiintata in anul 2012, societatea noastra este o companie de securitate moderna, avand logistica necesara desfasurarii actului de securitate in conditii optime si un management performant axat pe imbunatatirea continua a calitatii serviciilor prestate, oferind o gama complete de servicii de Securitate.</p>
                    <p>Prestam activitati de interventie rapida la obiectivul dumneavoastra, investigatie, protectia bunurilor si persoanelor, conform legii, cu personal selectionat si atestat in domeniu.</p>
                    <p>Oferim servicii de investigatii, filaje, urmariri supravegheri, verificarea datelor personale ale angajatilor firmei dumneavoastra, de asemenea cu personal selectionat si atestat in domeniu.</p>
                    <p>Proiectarea si instalarea sistemelor de alarma la efractie, la incendiu, sistemelor de control acces, sistemelor de supraveghere video o facem in colaborare cu EPIC INDUSTRIES SRL.</p>
                    <p>Partenerii nostrii de seama sunt SC TDM SECURITY si SC PHOENIX GUARD SRL.</p>
                    <p>Printre clientii nostrii se enumera Dunca Expeditii, SC Piete SA, SC ELF ROAD SRL, Liceul Tehnologic Transporturi Auto Timisoara, SC Norebo SRL.</p>
                    <p>Deoarece ne dorim o calitate inalta a serviciilor prestate, investim continuu in pregatirea personalului pentru fiecare punct de lucru(conform legii si conform postului ocupat), precum si in echipamentul personalului (uniforma pentru lucru, spray lacrimogen, tomfa) si in modurile de comunicare (telefon, statie emisie receptie).</p>
                    <p>Suntem responsabili faţă de angajaţi prin respectarea drepturilor legale şi asigurarea condiţiilor decente de câştig şi muncă.</p>
                    <p>Suntem responsabili faţă de clienţi prin menţinere şi promovare, asigurînd fidelitate şi servicii de calitate superioară.</p>
                    <p>Suntem responsabili faţă de noi insine prin protejarea investiţiilor făcute şi asigurarea unui profit pentru dezvoltare în acest domeniu.</p>
                    <p>Obiectivele firmei sunt de a se angaja în mod eficient şi responsabil, cu personal calificat, în activităţi din domeniul pazei şi protecţiei persoanelor reşedinţelor, sediilor de firme, spaţiilor de producţie, complexelor comerciale, clădirilor şi instituţiilor publice.</p>
                    <p>Cu o viziune și o misiune clare, folosim cele mai bune servicii de securitate!</p>
                    <p>Echipa noastră dedicată este disponibilă pentru a vă ajuta să obțineți serviciul potrivi.</p>
                </MySection>
            </div>
        );
    }
}