import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import BannerImg from './../imgs/homepage_banner.jpg';
import BannerLogo from './../imgs/homepage_logo.jpg';
import BannerMap from './../imgs/homepage_map.jpg';
import VipLogo from './../imgs/Investigatii-vip-logo.png';
import img1 from './../imgs/homepage_img1.jpg';
import img2 from './../imgs/homepage_img2.jpg';
import img3 from './../imgs/homepage_img3.jpg';
import logoTdm from './../imgs/tdm.png';
import logoPhoenix from './../imgs/phoenix.png';

import HelmetMetaData from './../modules/MetaData/components/HelmetMetaData';


const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    ${props => props.top && css`
         margin-top: 200px;
         @media (max-width: 640px){
            margin-top: 0;
         }
    `}
    ${props => props.reverse && css`
        @media (max-width: 640px){                
            flex-direction:column-reverse;
            }
        `}
`;
const MyImgWrap = styled.div`
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;   
    ${props => props.border && css`
        border: solid 1px #ffffff;
    `}
`;

const MyImg = styled.img`
   width:100%;
`;
const MyFlexBoxP = styled.p`
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 4%;
    font-size:30px;
    font-weight:300;
    @media (max-width: 1250px){
        font-size:20px;
    }
    @media (max-width: 870px){
        font-size:15px;
    }
    @media (max-width: 640px){
        flex-basis: 100%;
        font-size:15px;
        text-align:center;
        padding:10px;
        }
`;
const MyBigTextBox = styled.div`
    font-size:30px;     
    padding:5%;
    text-align:center;
    font-weight:300;
    @media (max-width: 640px){
        font-size:15px;
    }
     ${props => props.top && css`
        margin-top: 115px;
         @media (max-width: 640px){
            margin-top: 0;
         }
        `}
     ${props => props.border && css`
         border:dotted 1px #e9eb1f;
        `}
    ${props => props.bold && css`
         font-weight:400;  
        `}
`;

const My3BoxesWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-weight:300;

     ${props => props.top && css`
        margin-top: 115px;
         @media (max-width: 640px){
            margin-top: 0;
         }
        `}
`;

const MyP = styled.p`
    font-size:20px;
     @media (max-width: 640px){
        font-size:15px;
    }
    ${props => props.top && css`
        margin-top: 40px;
         @media (max-width: 640px){
            margin-top: 5px;
            }
        `}
`;

const Box33PerCent = styled.div`
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 30%;
    text-align: center;
    margin: 2.5rem 1%;
     @media (max-width: 640px){
            flex-basis: 100%;
            margin: 2.5rem 0 0;
         }
`;
const MyTitle = styled.div`
    font-size:36px;
    @media (max-width: 640px){
        font-size:24px;    
    }
`;
const YellowBox = styled.div`
    background-color:#eaec1f;
    color:#000000;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    display:flex;
    padding:65px 0;
     @media (max-width: 640px){
         padding:20px 0;
    }
`;
const YellowTitle = styled.div`
    font-size:72px;
    text-transform:uppercase;
    text-align:center;
    @media (max-width: 640px){
        font-size:30px;
    }
`;
const YellowSubTitle = styled.div`
    font-size:24px;
    text-align:center;
    @media (max-width: 640px){
        font-size:15px;
    }
`;
const YellowPhone = styled.div`
    font-size:60px;
    font-weight:bold;
    text-align:center;
`;
const YellowWrap = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 55%;
    text-align: right;
    @media (max-width: 820px){
        flex-basis: 100%;
    }
    ${props => props.right && css`
        flex-basis: 43%;
        text-align:left;
        padding-left:2%;
        @media (max-width: 820px){
                flex-basis: 100%;
            }
        `}
`;
const LogosWrap = styled.div`
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    display:flex;
    margin: 150px 0;
    @media (max-width: 640px){
            margin: 0 0;
    }
`;
const LogosImg = styled.img`
    width:100%;
    max-width:231px;
`;

const BoxLogo = styled.div`
    color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    text-align: center;
    margin: 2.5rem 0;
     @media (max-width: 640px){
                padding: 0 5px;
        }
`;
const MyHeader = styled.header`
    position:relative;
    background:#111111;
`;

const BannerLogoImg = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    border:solid 1px #ffffff;
    max-width: 464px;
    width: 24%;
    @media (max-width: 640px){
        display:none;
    }
`;
const BannerMapImg = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    border:solid 1px #ffffff;
    max-width: 424px;
    width: 23%;
    @media (max-width: 640px){
        max-width:33%;
        left:0;
        position:relative;
        float:left;
    }
`;
const VipLogoImg = styled.img`
    display:none;
    @media (max-width: 640px){
        max-width:35%;
        display:block;
        left:0;
        top:0;
        position:absolute;
    }
`;
const MyH1 = styled.h1`
    color:#ffffff;
    font-size: 3rem;
    font-weight:500;
    text-align:center;
    text-transform:uppercase;
    padding:60px 0;
    @media (max-width: 960px){
     font-size: 2rem;
    }
     @media (max-width: 640px){
        background: #000000;
        clear: both;
        font-size: 30px;
        padding: 25px 0;
    }
`;
const MyMobileH1 = styled.h2`
    display:none;
     @media (max-width: 640px){
        display: inline-block;
        color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        float: left;
        right: 0;
        max-width: 65%;
        padding: 30px 15px;
    }
`;


export class Home extends Component {
  static displayName = Home.name;
   
  render () {
    return (
        <div>   
            <HelmetMetaData/>
            <MyHeader>
                <img src={BannerImg} alt="home banner" title="home banner" style={{ width: '100%' }} />
                <BannerLogoImg src={BannerLogo} alt="home banner" title="home banner" />
                <VipLogoImg src={VipLogo} alt="VipLogoImg" title="VipLogoImg" />
                <BannerMapImg src={BannerMap} alt="home banner" title="home banner" />
                <MyMobileH1>Servicii de securitate în care puteți avea încredere deplină!</MyMobileH1>
                <MyH1>Securitate privata</MyH1>                
            </MyHeader>
            <section>
                <FlexBox top>
                    <MyImgWrap border><MyImg src={img1} alt="home banner" title="home banner" /></MyImgWrap>
                    <MyFlexBoxP>Activitati de interventie rapida la obiectivul dumneavoastra,<br />investigatie,protectia bunurilor si persoanelor,conform legii,<br />cu personal selectionat si atestat in domeniu.</MyFlexBoxP>
                </FlexBox>
                <FlexBox reverse>
                    <MyFlexBoxP>Servicii de: investigatii, filaje, urmariri supravegheri, verificarea datelor personale ale angajatilor firmei dumneavoastra, de asemenea cu personal selectionat si atestat in domeniu.</MyFlexBoxP>
                    <MyImgWrap border><MyImg src={img2} alt="home banner" title="home banner" /></MyImgWrap>                
                </FlexBox>
                <FlexBox>
                    <MyImgWrap border><MyImg src={img3} alt="home banner" title="home banner" /></MyImgWrap>
                    <MyFlexBoxP>Proiectare si instalare: sisteme de alarma la efractie, la incendiu,sisteme de control acces, sisteme de supraveghere video. </MyFlexBoxP>
                </FlexBox>
            </section>
            <section>
                <MyBigTextBox top border bold>Societatea noastra este o companie de securitate moderna, avand logistica necesara desfasurarii actului de securitate in conditii optime si un management performant axat pe imbunatatirea continua a calitatii serviciilor prestate.</MyBigTextBox>
                <My3BoxesWrap top>
                    <Box33PerCent>
                        <MyTitle>Responsabilitate faţă de angajaţi</MyTitle>
                        <MyP top>Respectarea drepturilor legale şi asigurarea condiţiilor decente de câştig şi muncă</MyP>
                    </Box33PerCent>
                    <Box33PerCent>
                        <MyTitle>Responsabilitate faţă de clienţi</MyTitle>
                        <MyP top>Menţinere şi promovare, asigurînd fidelitate şi servicii de calitate superioară</MyP>
                    </Box33PerCent>
                    <Box33PerCent>
                        <MyTitle>Responsabilitate faţă de sine</MyTitle>
                        <MyP top>Protejarea investiţiilor făcute şi asigurarea unui profit pentru dezvoltare în acest domeniu</MyP>
                    </Box33PerCent>
                </My3BoxesWrap>
            </section>
            <section>
                <MyBigTextBox top>Obiectivele firmei sunt de a se angaja în mod eficient şi responsabil, cu personal calificat, în activităţi din domeniul pazei şi protecţiei persoanelor, reşedinţelor, sediilor de firme, spaţiilor de producţie, complexelor comerciale, clădirilor şi instituţiilor publice.</MyBigTextBox>
            </section>
            <section>
                <YellowBox>
                    <YellowWrap>
                        <YellowTitle>SUNATI INVESTIGATII VIP</YellowTitle>
                        <YellowSubTitle>Cu o viziune și o misiune clare, folosim cele mai bune servicii de securitate</YellowSubTitle>
                    </YellowWrap>
                    <YellowWrap right>
                        <YellowPhone>0762236351</YellowPhone>
                    </YellowWrap>
                </YellowBox>
            </section>
            <section>
                <LogosWrap>
                    <BoxLogo><LogosImg src={logoPhoenix} alt="phoenix"/></BoxLogo>
                    <BoxLogo><LogosImg src={logoTdm} alt="TDM"/></BoxLogo>
                </LogosWrap>
            </section>
        </div>
    );
  }
}
