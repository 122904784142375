import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const TextWrap = styled.div`
       ${props => props.hideme && css`            
            visibility:hidden;
        `}
     @media (max-width: 640px){          
        width:80%;
    }
        
`;

const TopInfo = styled.div`
    color:#ffffff;
    float:left;
    margin-right:10px;
    @media (max-width: 640px){     
        width:100%;
    }
`;

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white  mb-3" dark>

                <TextWrap hideme={!this.state.collapsed}>
                    <TopInfo>Tel: 0762236351</TopInfo>
                    <TopInfo>Fax: 0356004342</TopInfo>
                    <TopInfo>Email: investigatiivip@yahoo.ro</TopInfo>
                </TextWrap>
            
            <NavbarToggler style={{ borderColor: '#ffffff' }} onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                            <NavLink tag={Link} className="text-light" to="/" onClick={this.toggleNavbar} >Acasa</NavLink>
                </NavItem>
                <NavItem>
                            <NavLink tag={Link} className="text-light" to="/despre-noi" onClick={this.toggleNavbar} >Despre&nbsp;Noi</NavLink>
                </NavItem>
                <NavItem>
                            <NavLink tag={Link} className="text-light" to="/servicii" onClick={this.toggleNavbar} >Servicii</NavLink>
                </NavItem>
                <NavItem>
                            <NavLink tag={Link} className="text-light" to="/galerie" onClick={this.toggleNavbar} >Galerie</NavLink>
                </NavItem>
                <NavItem>
                            <NavLink tag={Link} className="text-light" to="/contact" onClick={this.toggleNavbar} >Contact</NavLink>
                </NavItem>
              </ul>
            </Collapse>
        </Navbar>
      </header>
    );
  }
}
